<template>
    <el-table
      ref="inputTable"
      class="table-responsive table-flush position-table table-tablet"
      header-row-class-name="thead-light"
      :data="tabletItems"
      row-key="id"
      :row-class-name="tableRowClassName"
      style="width: 100%; overflow: auto"              
      @selection-change="handleSelectionChange"
        >
    <el-table-column
      type="selection"
      class-name="pl-4"
      width="55">
    </el-table-column>
    >
    <el-table-column type="expand" width="100">
      <template slot-scope="props">
        <el-row>
          <el-col>
            <table>
              <tr>
                <th class="expanded-header">{{ $t('message.width') }}</th>
                <th class="expanded-header">{{ $t('message.height') }}</th>
                <th class="expanded-header">{{ $t('message.unit') }}</th>
                <th class="expanded-header">{{ $t('data_input.material_manager.perimeter_placeholder') }}</th>
                <th class="expanded-header"></th>
                <th class="expanded-header">{{ $t('message.id') }}</th>
                <th class="expanded-header">{{ $t('message.Konfektion') }}</th>
                <th class="expanded-header">{{ $t('message.additionalInformation') }}</th>
                <th class="expanded-header">{{ $t('message.result_customer') }}</th>
              </tr>
              <tr>
                <td class="expanded-row">{{ props.row.width }}</td>
                <td class="expanded-row">{{ props.row.height }}</td>
                <td class="expanded-row">{{ props.row.unit }}</td>
                <td class="expanded-row"><span v-if="props.row.extent">{{ props.row.extent | number }}</span></td>
                <td>
                  <el-tooltip
                    :content="$t('position.go-to-bhb')"
                    placement="top"
                    v-if="!props.row.additional"
                  >
                    <base-button
                      type="link"
                      size="sm"
                      @click="
                        $router.push({
                          path: `/project/${$route.params.id}/bhb/${props.row.position_id}`,
                        })
                      "
                    >
                      <span class="btn-inner--icon">
                        <i class="fas fa-edit text-black"></i>
                      </span>
                    </base-button>
                  </el-tooltip>
                </td>
                <td class="expanded-row">{{ props.row.pos.key }}</td>
                <td class="expanded-row">
                  <div
                    v-if="props.row.pos.pt_konfektionsstatus == 'green'"
                    class="konfektion konfektion-green"
                  ></div>
                  <div
                    v-else-if="props.row.pos.pt_konfektionsstatus == 'yellow'"
                    class="konfektion konfektion-yellow"
                  ></div>
                  <div
                    v-else-if="props.row.pos.pt_konfektionsstatus == 'red'"
                    class="konfektion konfektion-red"
                  ></div>
                  <div v-else class="konfektion"></div>
                  <i
                    v-if="props.row.pos.pt_hilfe"
                    class="fas fa-bell faa-shake animated"
                    :title="props.row.pos.pt_hilfe"
                  ></i>
                </td>
                <td class="expanded-row left">{{ props.row.pos.cl_info }}</td>
                <td class="expanded-row">{{ props.row.rueck_result_customer }}</td>
              </tr>
            </table>
          </el-col>
        </el-row>
      </template>
    </el-table-column>
      <el-table-column :label="$t('message.loadNote')" width="120">
        <template v-slot="{ row }">
          <el-row class="d-flex align-items-baseline">
            <el-col :span="4">
              <el-radio
                :disabled="(!currentUserRights.includes('Ladeliste_Abhaken') ||
                            project.state === 'ARCHIVED' ||
                            (row.lade_bemerkung && row.lade_bemerkung_checked === null)
                            )"
                :value="row.state_lade === 'GELADEN'"
                @change="changeState(row, 1)"
                class="loaded-radio"
              ></el-radio>
            </el-col>
            <el-col :span="20">
              <base-button
                type="link"
                size="md"
                @click="showComments(row)"
                :disabled="project.state === 'ARCHIVED'"
              >
                <span class="btn-inner--icon">
                  <i v-if="row.lade_bemerkung" class="fas fa-comment"></i>
                  <i v-else class="far fa-comment text-black"></i>
                </span>
              </base-button>
            </el-col>
          </el-row>
          <el-row v-if="row.user_lade_ref">
            <el-col :span="24">
              {{ row.user_lade_ref.name }},
              {{ row.date_lade | datetime }}
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('message.ride')"
        :prop="'pos.ride.vehicle.type'"
        sortable
        width="120"
      >
      <template v-slot="{row}">
        <base-button
          type="link"
          size="sm"
          class="text-left"
          @click="showRides(row)"
        >
          {{ row.pos.ride 
          ? (row.pos.ride.notice ? row.pos.ride.vehicle.type +' / '+ row.pos.ride.notice : row.pos.ride.vehicle.type) 
          : ''}}
        </base-button>
      </template>
      </el-table-column>

      <el-table-column
        sortable
        label="PT-NR."
        width="100"
        prop="pos.pt_number"
      >
      </el-table-column>
      <el-table-column
        prop="category.name"
        :label="$t('global.category')"
        width="150"
        sortable
      >
        <template v-slot="{row}">
          {{ row | category(currentLocale) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="pos.bhb_position.name"
        :label="$t('message.positionBHB')"
        width="130"
        sortable
      >
      </el-table-column>
      <el-table-column
        :label="$t('message.materialConstruction')"
        width="220"
        sortable
        :sort-method="sortMaterial"
      >
        <template v-slot="{ row }">
          <div v-if="row.article && !row.additional">
            {{ row | material(currentLocale) }}
          </div>
          <div v-if="row.additional">
            {{ row.article }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('message.amount')"
        align="right"
        header-align="right"
        width="100"
        prop="quantity"
        sortable
      >
        <template v-slot="{ row }">
          {{ row.quantity | number }}
        </template>
      </el-table-column>
      
    </el-table>
 
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { setTimeout } from "timers";

export default {
  name: "LoadingListMobile",
  computed: {
    ...mapGetters([
      "project",
      "positions",
      "projectAddressPositions",
      "currentProjectAddress",
      "pickingListPositions",
      "kommStateValues",
      "kommFilterValue",
      "currentUserRights",
      "openProjects",
      "currentUser",
      "currentLocale",
      "authorisedStateValues",
    ]),
  },
  props: {
    tabletItems: {
			type: [Array, Object],
    }
  },
  data() {
    return {
    };
  },
  watch: {
    positions(val) {
      if (val !== null) {
        setTimeout(() => {}, 5000);
      }
    },
  },
  methods: {
    ...mapActions([
      "updatePositionSort",
      "updateProject",
      "loadAdditional",
      "getFullProject",
      "getProjectPersons"
    ]),
    sortMaterial(a, b) {
      let list1 = a.additional === true ? a.article : a.variant.InternalDescription;
      let list2 = b.additional === true ? b.article : b.variant.InternalDescription;

      if(list1 < list2) { return -1; }
      if(list1 > list2) { return 1; }

      return 0;
    },
    showRides(row) {
      this.$emit('showRidesClick', row);
    },
    showComments(row) {
      this.$emit('showCommentsClick', row);
    },
    edit(row) {
      this.$emit('editClick', row);
    },
    askDelete(row) {
      this.$emit('deleteClick', row);
    },
    handleSelectionChange(val) {
      this.$emit('bulkSelected', val)
    },
    changeState(row, value = null) {
      this.$emit('changeStateClick', {item: row, value: value});
    },
    tableRowClassName({ row, rowIndex }) {
      let classes = "no-hover";
      if (this.$route.params.positionId) {
        let pos_item = parseInt(this.$route.params.positionId);
        if (pos_item === row.position_id || pos_item === row.id) classes += " highlighted";
      }
      if (row.state_komm === "GELADEN") classes += " loaded";
      if (rowIndex % 2 == 0) classes += " striped";
      return classes;
    },
  },
  mounted() {
  },
};
</script>
<style>
.table-tablet table.el-table__body td {
  padding-left: 0.5rem;
}
.table-tablet table.el-table__body .el-table__expanded-cell {
  background-color: #e8ebef;
}
.table-tablet table.el-table__body .expanded-row {
  text-align: center;
}.table-tablet table.el-table__body .expanded-row.left {
  text-align: left;
}
.table-tablet table.el-table__body .expanded-header {
  padding-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.65rem;
  font-weight: 600;
  letter-spacing: 1px;
}
</style>
